import { render, staticRenderFns } from "./UIServerTableDialog.vue?vue&type=template&id=62b2e6a1&scoped=true&"
import script from "./UIServerTableDialog.js?vue&type=script&lang=js&"
export * from "./UIServerTableDialog.js?vue&type=script&lang=js&"
import style0 from "./UIServerTableDialog.styl?vue&type=style&index=0&id=62b2e6a1&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62b2e6a1",
  null
  
)

export default component.exports